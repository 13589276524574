import styles from './InnerHtml.module.css';

export default function InnerHtml({ html, className }) {
  return (
    <span
      className={className || styles.container}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}
