import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { UAParser } from 'ua-parser-js';
import background from './ProductSharePage/background.png';
import logo from './ProductSharePage/logo_square.png';

// import VConsole from 'vconsole';
// // eslint-disable-next-line no-unused-vars
// const vConsole = new VConsole();

let hidden;
let visibilityChange;

function getSupportedProperty() {
  if (typeof document === 'undefined') return;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
}

getSupportedProperty();

function isPageHidden() {
  if (typeof hidden === 'undefined') return false;
  return document[hidden];
}

function checkOpen(failure, timeout) {
  const timer = setTimeout(() => {
    const pageHidden = isPageHidden();
    if (!pageHidden) {
      failure();
    }
  }, timeout);

  if (typeof visibilityChange !== 'undefined') {
    document.addEventListener(visibilityChange, () => {
      clearTimeout(timer);
    });
  } else {
    window.addEventListener('pagehide', () => {
      clearTimeout(timer);
    });
  }
}

export default function ProductSharePage() {
  const { id } = useParams();
  const target = `cotemobile://m.cote.com/product_detail/${id}`;
  const linkRef = useRef();

  const handleOpen = (e) => {
    const browserName = new UAParser().getBrowser().name;
    if (['WeChat', 'Instagram', 'Facebook'].includes(browserName)) {
      alert('请使用默认浏览器打开');
      e.preventDefault();
    } else {
      checkOpen(() => {
        const parser = new UAParser();
        if (parser.getOS().name === 'iOS') {
          // TODO: replace app link
          window.location.href =
            'https://apps.apple.com/cn/app/%E5%BE%AE%E4%BF%A1%E8%AF%BB%E4%B9%A6/id952059546';
        } else {
          // TODO: replace app link
          window.location.href =
            'https://a.app.qq.com/o/simple.jsp?pkgname=io.dushu.fandengreader';
          // const deviceVender = parser.getDevice().vendor;
          // switch (deviceVender) {
          //   case 'Huawei':
          //     window.open('https://url.cloud.huawei.com/hJZ5ZKgMbm');
          //     break;
          //   case 'Xiaomi':
          //     window.open('https://url.cloud.huawei.com/hJZ5ZKgMbm');
          //     break;
          //   // case 'Huawei':
          //   // break;
          //   // case 'Huawei':
          //   // break;
          //   default:
          //     alert('请前往应用市场安装COTE');
          // }
        }
      }, 2000);
    }
  };

  // useEffect(() => {
  //   linkRef.current.click();
  // }, [target]);

  return (
    <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>
      <div
        style={{
          height: '100%',
          background: `url(${background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          opacity: 0.5,
        }}
      >
        <div style={{ height: '100%', backdropFilter: 'blur(6px)' }} />
      </div>
      <div
        style={{
          height: '100%',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ paddingTop: 100, textAlign: 'center' }}>
          <img src={logo} alt="" style={{ height: 100, marginBottom: 10 }} />
          <div style={{ marginBottom: 50, fontSize: 20 }}>COTE</div>
          <div style={{ marginBottom: 4, fontSize: 18 }}>
            请使用默认浏览器打开
          </div>
          <div style={{ marginBottom: 160, fontSize: 14 }}>
            COTE 覆盖瑞士全境的高端奢华时尚杂志
          </div>
        </div>
        <div style={{ paddingBottom: 120, fontSize: 14, textAlign: 'center' }}>
          <a
            ref={linkRef}
            href={target}
            style={{
              display: 'inline-block',
              background: '#a87e6f',
              color: 'white',
              fontSize: 18,
              height: 40,
              lineHeight: '40px',
              width: 200,
              border: 'none',
              borderRadius: 4,
              outline: 'none',
              textDecoration: 'none',
            }}
            onClick={handleOpen}
          >
            打开APP
          </a>
        </div>
      </div>
    </div>
  );
}
