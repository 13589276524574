import { useEffect, useRef } from 'react';

// import VConsole from 'vconsole';
// // eslint-disable-next-line no-unused-vars
// const vConsole = new VConsole();

export default function HomePage() {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current.setAttribute('playsinline', true);
  }, []);

  return (
    <div>
      <h1>Welcome</h1>
      <video
        ref={videoRef}
        style={{ width: 300, height: '100%' }}
        loop
        muted
        autoPlay
        playsinline
        controls
      >
        <source
          src="https://dev-temp-sh.oss-cn-shanghai.aliyuncs.com/%E5%8D%B3%E6%99%BA%20-%20%E5%A4%96%E9%83%A8%E9%A1%B9%E7%9B%AE%E6%B2%9F%E9%80%9A%202022-04-04%2022.55.01.mp4?Expires=1657795624&OSSAccessKeyId=TMP.3Kjz6cepTyFKhsJfti7NtHYKytLBv9dyFmEP5VifrVRw8JRBLHAFhrr8k3SVUhQxCZZeje8hrs6W15vgPWQRoz7WGib7N1&Signature=fV8iiE%2FWvgWz6pgvDMkvGBDsdYY%3D"
          type="video/mp4"
        />
      </video>
    </div>
  );
}
