import { useEffect, useState, useRef, useContext, createContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WebviewFlutterChannel } from 'webview_flutter_channel';
import { /*primaryBlack,*/ primaryColor, secondaryBlack } from '../theme';
import InnerHtml from '../views/InnerHtml';
import curveImage from './ProductDetailPage/curve.svg';
// import moreImage from './ProductDetailPage/more.svg';
import styles from './ProductDetailPage/style.module.css';

// import VConsole from 'vconsole';
// eslint-disable-next-line no-unused-vars
// const vConsole = new VConsole();

const i18nConfig = {
  chinese: {
    colon: '：',
    priceEstimate: '价格预估',
    details: '商品详情',
    recommendation: '猜您喜欢',
  },
  english: {
    colon: ': ',
    priceEstimate: 'Estimate',
    details: 'DETAILS',
    recommendation: 'Guess you like',
  },
};

const ChannelContext = createContext();

export default function ProductDetailPage() {
  const windowWidth = window.screen.width;
  const containerRef = useRef();
  const videoRef = useRef();
  const [searchParams] = useSearchParams();
  const [product, setProduct] = useState(null);
  const [recommendations, setRecommendations] = useState([]);

  const i18n = i18nConfig[searchParams.get('language')] ?? i18nConfig.chinese;
  console.log(searchParams.get('language'));
  console.log(i18n);

  useEffect(() => {
    if (product && videoRef.current) {
      try {
        videoRef.current.setAttribute('playsinline', true);
      } catch (error) {
        console.error(error);
      }
    }
  }, [product]);

  const [channel] = useState(
    () =>
      new WebviewFlutterChannel({
        prefix: searchParams.get('channel'),
        methods: {
          scrollToTop: () => {
            const container = containerRef.current;
            const beginTop = container.scrollTop;
            let beginTime;
            const duration = 260;

            const step = (timestamp) => {
              if (beginTime === undefined) beginTime = timestamp;
              let elapsed = timestamp - beginTime;
              // easeInQuad
              // const scrolledHeight =
              // beginTop * (elapsed /= duration) * elapsed + 0;
              // easeInOutSine
              const scrolledHeight =
                (-beginTop / 2) *
                (Math.cos((Math.PI * elapsed) / duration) - 1);
              console.log(beginTop, scrolledHeight);
              container.scrollTop = beginTop - scrolledHeight;
              if (timestamp - beginTime < duration) {
                window.requestAnimationFrame(step);
              }
            };

            window.requestAnimationFrame(step);
            return 'is scrolling to top';
          },
          getProductData: (data) => {
            setProduct(data);
          },
          getRecommendations: (data) => {
            setRecommendations(data ?? []);
          },
        },
      }),
  );

  useEffect(() => () => channel.dispose(), [channel]);

  // console.log({ product });
  // console.log({ recommendations });
  if (!product) return null;

  return (
    <ChannelContext.Provider value={channel}>
      <div
        className={styles.container}
        ref={containerRef}
        style={{
          background: '#fff',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          // 隐藏滚动条
          paddingLeft: 10,
          marginLeft: -10,
          paddingRight: 10,
          marginRight: -10,
        }}
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: (468 * windowWidth) / 406,
            }}
          >
            {product.bannerType === 1 ? (
              <video
                ref={videoRef}
                style={{ width: '100%', objectFit: 'fill' }}
                // style={{ width: 200, height: 200 }}
                loop
                muted
                autoPlay
                playsinline
                // controls
                // poster={`http://app-test.jizhi-group.com/${product.img}`}
                poster={product.img}
              >
                <source
                  // src={`http://app-test.jizhi-group.com/${product.bannerVideo}`}
                  src={product.bannerVideo}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                alt=""
                // src={`http://app-test.jizhi-group.com/${product.img}`}
                src={product.img}
                style={{ width: '100%' }}
              />
            )}
          </div>
          <div style={{ position: 'relative' }}>
            <div
              style={{
                minHeight: '100vh',
                width: '100vw',
                backgroundImage: `url(${curveImage})`,
                backgroundSize: '100% auto',
                backgroundPosition: `0 ${(270 * windowWidth) / 406}px`,
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div style={{ height: (386 * windowWidth) / 406 + 14 }} />
              <div
                style={{
                  background: '#fff',
                  marginTop: 20,
                  padding: '0 20px 20px',
                  borderRadius: '80px 0 0 0',
                }}
              >
                <div
                  className={styles.center}
                  style={{
                    color: primaryColor,
                    fontSize: 20,
                    marginBottom: 28,
                    fontWeight: 500,
                  }}
                >
                  <div
                    style={{
                      display: 'inline-block',
                      maxWidth: '80%',
                    }}
                  >
                    {product.title}
                  </div>
                </div>
                {!!product.digest && (
                  <div
                    className={styles.center}
                    style={{
                      color: secondaryBlack,
                      fontSize: 14,
                      marginBottom: 36,
                    }}
                  >
                    {product.digest}
                  </div>
                )}
                {!!product.price && !!product.showPrice && (
                  <div
                    className={styles.center}
                    style={{
                      color: primaryColor,
                      fontSize: 24,
                      marginBottom: 28,
                      // fontWeight: 500,
                    }}
                  >
                    {`${i18n.priceEstimate}${i18n.colon}${product.price}`}
                  </div>
                )}
                {!!product.content && !!product.showPrice && (
                  <SectionTitle>{i18n.details}</SectionTitle>
                )}
                {!!product.content && (
                  <InnerHtml html={product.content} />
                )}
                <Divider />
                {/* {recommendations.length > 0 && (
                    <SectionTitle>{i18n.recommendation}</SectionTitle>
                )} */}
                {recommendations.length > 0 && (
                    <ProductList products={recommendations} />
                )}
                <div style={{ height: 72 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ChannelContext.Provider>
  );
}

function SectionTitle({ children }) {
  return (
    <div
      className={styles.center}
      style={{ color: primaryColor, fontSize: 22, marginBottom: 26 }}
    >
      {children}
    </div>
  );
}

function Divider({ style }) {
  return (
    <div
      style={{
        borderTop: '0.5px solid rgba(0, 0, 0, 0.2)',
        marginTop: 26,
        marginBottom: 26,
        ...style,
      }}
    />
  );
}

// function PriceTitle({ product. }) {
//   return (
//     <div
//       className={styles.center}
//       style={{
//         color: primaryColor,
//         fontSize: 24,
//         marginBottom: 28,
//         // fontWeight: 500,
//       }}
//     >
//       {`${i18n.priceEstimate}${i18n.colon}${product.price}`}
//     </div>
//   );
// }

function ProductList({ products }) {
  return (
    <div>
      {buildListRows(products).map((row, index) => (
        <ProductListRow key={index} row={row} />
      ))}
    </div>
  );
}

function ProductListRow({ row, itemGap = 16, rowGap = 16 }) {
  const screenWidth = window.screen.width;
  const rowWidth = screenWidth - 40;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: rowGap,
      }}
    >
      {row.children.map((item) => (
        <ProductCard
          key={item.id}
          product={item}
          width={(rowWidth - (row.column - 1) * itemGap) / row.column}
        />
      ))}
    </div>
  );
}

function ProductCard({ product: { id, cover, title }, width }) {
  const imageColumn = 2;
  const channel = useContext(ChannelContext);

  return (
    <div
      style={{ width: width }}
      onClick={() => {
        channel.requestDartMethod('goToDetailPage', id);
      }}
    >
      <div
        style={{
          backgroundImage: `url(${cover})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: width,
          height: imageColumn === 1 ? (145 / 339) * width : width,
          borderRadius: imageColumn === 1 ? 0 : 28,
        }}
      />
      <div
        style={{
          marginTop: 4,
          fontSize: 12,
          color: secondaryBlack,
          textAlign: 'center',
        }}
      >
        {title}
      </div>
    </div>
  );
}

function buildListRows(items) {
  const result = [];
  items.forEach((item) => {
    const size = result.length;
    const resultLast = result[size - 1];
    if (resultLast && resultLast.children.length < resultLast.column) {
      resultLast.children.push(item);
    } else {
      result.push({ children: [item], column: item.imageColumn ?? 2 });
    }
  });
  return result;
}
