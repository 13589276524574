/* eslint-disable max-classes-per-file */
/* eslint-disable no-console */
import { v4 as uuidV4 } from 'uuid';

/// jsRequest dart method, then dartResponse to js
/// dartRequest js method, then jsResponse to dart
const ChannelName = {
  jsRequest: (prefix) => `${prefix}_js_request`,
  jsResponse: (prefix) => `${prefix}_js_response`,
  dartRequest: (prefix) => `${prefix}_dart_request`,
  dartResponse: (prefix) => `${prefix}_dart_response`,
};

const ResponseType = {
  data: 'data',
  error: 'error',
};

export class WebviewFlutterChannel {
  constructor({ prefix, methods }) {
    this.prefix = prefix;
    this._jsMethods = {};
    /**
     * string: { resolve: (data) -> any, (error) -> any }
     */
    this._pendingJsRequests = {};
    Object.assign(this._jsMethods, methods);
    window[ChannelName.dartRequest(this.prefix)] = this._dartRequestJsMethod.bind(this);
    window[ChannelName.dartResponse(this.prefix)] = this._dartResponseToJsRequest.bind(this);

    this.requestDartMethod('ready');
  }

  dispose() {
    window[ChannelName.dartRequest(this.prefix)] = null;
    window[ChannelName.dartResponse(this.prefix)] = null;
  }

  async requestDartMethod(methodName, params) {
    const callId = `js_request_${uuidV4()}`;
    this._callDartMethod(
      ChannelName.jsRequest(this.prefix),
      callId,
      methodName,
      params,
    );
    return new Promise((resolve, reject) => {
      this._pendingJsRequests[callId] = { resolve, reject };
    });
  }

  async _callDartMethod(channelName, callId, methodName, methodParams) {
    console.log(
      'Js request dart method: ',
      channelName,
      callId,
      methodName,
      this.prefix,
    );
    await window[channelName].postMessage(
      JSON.stringify({ callId, methodName, methodParams }),
    );
  }

  _dartRequestJsMethod(callId, methodName, methodParams) {
    console.log('Dart request js method:', callId, methodName, methodParams);
    const method = this._jsMethods[methodName];
    const jsResponseChannelName = ChannelName.jsResponse(this.prefix);
    try {
      const parsedParams = JSON.parse(methodParams);
      if (!method) {
        this._callDartMethod(
          jsResponseChannelName,
          callId,
          ResponseType.error,
          `Dart request unknown js method: ${methodName}`,
        );
        return;
      }
      const result = method(...parsedParams);
      if (result && result.then && result.catch) {
        result
          .then((value) => {
            this._callDartMethod(
              jsResponseChannelName,
              callId,
              ResponseType.data,
              value,
            );
          })
          .catch((error) => {
            this._callDartMethod(
              jsResponseChannelName,
              callId,
              ResponseType.error,
              String(error),
            );
          });
      } else {
        this._callDartMethod(
          jsResponseChannelName,
          callId,
          ResponseType.data,
          result,
        );
      }
    } catch (error) {
      this._callDartMethod(
        jsResponseChannelName,
        callId,
        ResponseType.error,
        String(error),
      );
    }
  }

  _dartResponseToJsRequest(callId, methodName, methodParams) {
    console.log(
      'Dart response to js request: ',
      callId,
      methodName,
      methodParams,
    );
    const promise = this._pendingJsRequests[callId];
    try {
      const parsedParams = JSON.parse(methodParams);
      if (!promise) {
        throw Error(`Dart response unknown js request: ${callId}`);
      }

      if (methodName === ResponseType.data) {
        promise.resolve(parsedParams);
      } else if (methodName === ResponseType.error) {
        promise.reject(parsedParams);
      } else {
        promise.reject(new Error(`Unknown dart response type: ${methodName}`));
      }
    } catch (error) {
      if (promise) {
        promise.reject(error);
      }
    }
  }
}
