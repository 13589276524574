import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WebviewFlutterChannel } from 'webview_flutter_channel';
import InnerHtml from '../views/InnerHtml';

// import VConsole from 'vconsole';
// // eslint-disable-next-line no-unused-vars
// const vConsole = new VConsole();

export default function PolicyPage({ a }) {
  const [searchParams] = useSearchParams();
  const [policy, setPolicy] = useState(null);

  const [channel] = useState(
    () =>
      new WebviewFlutterChannel({
        prefix: searchParams.get('channel'),
        methods: {
          getPolicyData: (data) => {
            setPolicy(data);
          },
        },
      }),
  );

  useEffect(() => () => channel.dispose(), [channel]);

  if (!policy) return null;

  return (
    <div style={{ padding: 20 }}>
      <InnerHtml html={policy} />
    </div>
  );
}
