import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductDetailPage from './pages/ProductDetailPage';
import ProductSharePage from './pages/ProductSharePage';
import PolicyPage from './pages/PolicyPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/h5/product/:id" element={<ProductDetailPage />} />
        <Route path="/h5/product_detail/:id" element={<ProductSharePage />} />
        <Route path="/h5/policy" element={<PolicyPage />} />
        <Route path="/h5" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
